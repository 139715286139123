import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";
import ButtonShowMore from "../ButtonShowMore/ButtonShowMore";

function FrameworkTitle({ backgroundImage, backgroundColor, titulo, titleColor, id, hasMoreContents = false }) {

  const renderTitle = <p>{titulo}</p>;

  const renderFrameworkHomeBackground = (
    <div
      className={style.frameworkTitleBackground}
      style={{
        backgroundImage: `url(${backgroundImage?.url})`,
        backgroundColor,
      }}
    >
      <p className={style.frameworkTitle} style={{ color: titleColor }}>
        {titulo}
      </p>
    </div>
  );

  return (
    <div className={style.titulo}>
      {!backgroundImage && !backgroundColor ? renderTitle : renderFrameworkHomeBackground}
      {hasMoreContents && <ButtonShowMore id={id} />}
    </div>
  );
}

FrameworkTitle.propTypes = {
  backgroundImage: PropTypes.shape({
    url: PropTypes.string,
  }),
  backgroundColor: PropTypes.string,
  titulo: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  hasMoreContents: PropTypes.bool.isRequired,
};

export default FrameworkTitle;
