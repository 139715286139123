import React, {useEffect, useState} from 'react';
import {Redirect} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import i18n from "@app/i18n";
import {URL_LEGAL, URL_LOGIN, URL_HOME} from '@components/Routes/Location';
import './Legal.css';
import AlertNotification from "@components/GUI/Alert/Alert";
import {legal, interops} from '@core'
import useOutput from "../../../../app/src/Utils/Hooks/useOutput";
import {MULTITENANT_CONSTANTS, TENANT, TENANT_PETISGO} from "../../../../app/src/Resources/Multitenant/tenantConstants";
import {THEME_DARK} from "../../../../app/src/Components/common/OnBoarding/OnBoardingConstants";
import {useKeycloak} from "@react-keycloak/web";
import {useDispatch, useSelector} from "react-redux";
import {turnOffLoadingActionCreator} from "../../../../app/src/actions/commonActions";

function Legal({guest}) {

    const outputFn = useOutput();
    const [legalText, setLegalText] = useState(null);
    const [aceptadasCU, setAceptadasCU] = useState(false);
    const [aceptadas, setAceptadas] = useState(null);
    const [msgNecesarioAceptar, setMsgNecesarioAceptar] = useState(false);
    const {t} = useTranslation();

    const [theme, setTheme] = useState(null);
    const dispatch = useDispatch();
    const {keycloak} = useKeycloak();
    const tenantStyles = useSelector(state => state.commonState.tenantStyles);
    const [controller, setController] = useState(null);

    const LEGAL_CONDITIONS_ACCEPTED = "AcceptedLegalConditions"

    const turnOffLoading = () => {
        dispatch(turnOffLoadingActionCreator())
    };

    useEffect(() => {
        turnOffLoading();
        localStorage.setItem("OTT-Guest", true);
    }, [])


    useEffect(() => {
        const controller = new legal.controller;
        let binding = controller.bind(handleModel, o => outputFn(o, handleOutput, legal))
        setController(controller)

        return () => {
            controller.unbind(binding);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (keycloak) {
            setTheme(keycloak.clientId)
        }
    }, [keycloak]);

    const handleModel = (o) => {
        if (o.legalText) {
            setLegalText(o.legalText);
            i18n.changeLanguage(o.legalTextLanguage) ?
                i18n.changeLanguage(i18n.changeLanguage(o.legalTextLanguage)) :
                i18n.changeLanguage(i18n.changeLanguage(MULTITENANT_CONSTANTS[TENANT]?.defaultLocale))
        }
    };

    const handleOutput = (o) => {
        if (interops.getKotlinName(o) === LEGAL_CONDITIONS_ACCEPTED) {
            setAceptadas(true);
        }
    };

    const handleChange = (e) => {
        setAceptadasCU(e.target.checked)
    }

    const handleSubmit = () => {
        if (aceptadasCU) {
            setMsgNecesarioAceptar(false);
            const aceptCond = new legal.events.AcceptConditions(true);
            controller.dispatch(aceptCond);

        } else {
            setMsgNecesarioAceptar(true);
        }
    }

    if (aceptadas) {
        return (
            <Redirect to={`${URL_LEGAL}${URL_LOGIN}${URL_HOME}`}/>
        );
    }

    const backgroundImageByTenant = () => {
        if ((TENANT !== TENANT_PETISGO) || (TENANT === TENANT_PETISGO && theme !== THEME_DARK))
            return `url(${tenantStyles?.backgroundRegister})`;
    }

    return (
        <div className="Legal" style={{ backgroundImage:backgroundImageByTenant()}}>

            <header className="App-header">
                <div className="Logo-Barra" alt="logo" />
            </header>
            <div className="Legal-box">
                <div className="TituloCondicions">
                    <h1>{t('register.condicionsusotitulo')}</h1>
                </div>
                <div className="Description">
                    <div className="legal-text" dangerouslySetInnerHTML={{__html: legalText}}/>
                </div>
                {msgNecesarioAceptar &&
                <AlertNotification type="error" showtitle={false} text={t('legal.necesarioaceptar')}/>
                }
                <div className="legal-question">
                    <label className="legal-terminos-servicio">
                        <input type="checkbox" value={aceptadasCU} onClick={handleChange}/>
                        {t('legal.aceptocondicions')}
                    </label>
                    <button className="NextButton button-focused" onClick={handleSubmit}>{t('legal.aceptar')}</button>

                </div>
            </div>
        </div>
    );
}

export default Legal;
