import React, {useEffect} from "react";
import { NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { checkMenuActive } from "../../../../../Utils/utils";
import { useSelector } from "react-redux";

const MenuSectionsComponent = ({ handleChangeSection, localSectionSelected, sections, changeSection, changeDynamicSection, getIconSection }) => {
  const sectionSelected = useSelector((state) => state.commonState.sectionSelected);

  const updateIcon = () => {
    const filteredSection = sections?.filter((section) =>
      section.section.screenId == localSectionSelected
    )
    handleChangeSection(filteredSection[0].section.iconId)
  }

  useEffect(() => {
    !!sectionSelected || updateIcon()
  }, [])

  const getSectionIdUrl = (section) => {
      return {
          screenId : section.section.screenId,
          url: section.url,
          iconId: section.section.iconId,
      }
  }

  return (
    <div>
      <>
      {!!sections ?
        sections.map((section, index) =>
          section?.section.screenId ? (
            <OverlayTrigger
              key={index}
              delay={{ hide: 450, show: 300 }}
              placement="bottom"
              overlay={(props) => <Tooltip {...props}>{section?.section.name}</Tooltip>}
            >
              <NavLink
                key={section?.section.iconId}
                to={{
                  pathname: section.url.toLowerCase(),
                  aboutProps: {
                    dinamic: section.section.screenId,
                  },
                }}
                onClick={() => changeDynamicSection(getSectionIdUrl(section))}
                className={"MenuSection " + section.section.iconId}
                isActive={(match, location) => checkMenuActive(match, location, section, sectionSelected, sections)}
              >
                {getIconSection(section.section.iconId)}
              </NavLink>
            </OverlayTrigger>
          ) : section.section.type.name$ === "UNKNOWN" ? (
            <></>
          ) : (
            <OverlayTrigger
              key={index}
              delay={{ hide: 450, show: 300 }}
              placement="bottom"
              overlay={(props) => <Tooltip {...props}>{section?.section.name}</Tooltip>}
            >
              <NavLink
                to={{
                  pathname: section.url,
                }}
                onClick={() => changeSection(section.section)}
                className={"MenuSection " + section.section.iconId}
                key={section.section.iconId}
              >
                {getIconSection(section.section.iconId)}
              </NavLink>
            </OverlayTrigger>
          ),
        ) : "Si no tengo nada no pinto nada"
        }
      </>
    </div>
  );
};

export default MenuSectionsComponent;
