import { splash, interops } from "@core";

/**
 Obten a url completa a unha ruta relativa
 */
export const getFullURL = function (path) {
  if (path) {
    path = path.replace("./", "");
    if (path.length === 0 || path.charAt(0) !== "/") {
      path = "/" + path;
    }
    return window.location.href.split("#")[0] + "#" + path;
  } else {
    return window.location.href.split("#")[0] + "#/";
  }
};

/**
 * Obten a seguinte pantalla a partir do controlador
 * @param controller
 * @returns {Promise<unknown>}
 */
export const getNextScreen = function (controller) {
  return new Promise(function (resolve, reject) {
    // Recarga App.js -
    // Esto esta causando que se cree una nueva store para el splash ya que crea un nuevo controller
    // Puede estar casuando un nuevo renderizado de App.js?
    // También se esta creando un binding que nunca se libera. Habria que liberarlo cuando no sea necesario
    const splashController = new controller();
    let binding = splashController.bind(
      (model) => {
        if (model.nextScreen) {
          resolve(SECTION_URL_BY_NAME[model.nextScreen.name]);
        }
      },
      (output) => {
        if (interops.getKotlinName(output) === "UnexpectedError") {
          const errorCode = output.code;
        }
        if (interops.getKotlinName(output) === "RequestTimedOut") {
        }
      },
    );
    splashController.dispatch(splash.events.GetFirstScreen);
  });
};

/**
 * Lista nombres de URLs
 */
export const URL_NAME_HOME = "HOME";
export const URL_NAME_MAIN = "MAIN";
export const URL_NAME_LOGIN = "LOGIN";
export const URL_NAME_LEGAL = "LEGAL";
export const URL_NAME_SELECT_LOGIN = "SELECTLOGIN";
export const URL_NAME_SIGN_UP = "SIGNUP";
export const URL_NAME_SEARCH = "SEARCH";
export const URL_NAME_CATALOGUE = "CATALOGUE";
export const URL_NAME_LIVE = "LIVE";
export const URL_NAME_CONTENT = "CONTENIDO";
export const URL_NAME_PROFILE = "PROFILE";
export const URL_NAME_RANKING = "RANKING";
export const URL_NAME_PUBLISH = "PUBLISH";
export const URL_NAME_REJECT = "REJECT";
export const URL_NAME_PRODUCTS = "PRODUCTS";

/**
 * Lista de URLs
 */
export const URL_LEGAL = "/legal";
export const URL_HOME = "/home";
export const URL_SELECTLOGIN = "/selectage";
export const URL_REGISTER = "/register";
export const URL_LOGIN = "/login";
export const URL_SPLASH = "/splash";
export const URL_DASHBOARD = "/dashboard";
export const URL_SEARCH = "/search";
export const URL_CATALOGUE = "/catalogue";

export const URL_SECTION_CLUBS = "/section/clubs";
export const URL_LIVE = "/live";
export const URL_CONTENT = "/content";
export const URL_USER = "/user";
export const URL_USERDATA = "/userdata";
export const URL_USERCATEGORIES = "/usercategories";
export const URL_USERAVATAR = "/useravatar";
export const URL_PLAYER = "/player";
export const URL_VIDEODETAIL = "/videodetail";
export const URL_ERRORPAGE = "/error";
export const URL_RANKING = "/ranking";
export const URL_PERFIL = "/perfilusuario";
export const URL_PUBLISH = "/publish";
export const URL_REJECT = "/reject";
export const URL_PRODUCTS = "/products";
export const URL_SUCCESS_CHECKOUT = "/success-checkout";
export const URL_CANCEL_CHECKOUT = "/cancel-checkout";

export const URL_SECTION = "/section";


export const ARRAY_URLS_OPEN_PAGES = [URL_LEGAL, URL_HOME, URL_SELECTLOGIN, URL_REGISTER, URL_SPLASH, URL_DASHBOARD, URL_SEARCH,
  URL_CATALOGUE, URL_LIVE, URL_CONTENT, URL_PLAYER, URL_VIDEODETAIL, URL_ERRORPAGE, URL_PRODUCTS, URL_SECTION];




/**
 * Relación Nombre - URL
 */
export const SECTION_URL_BY_NAME = {
  [URL_NAME_HOME]: URL_HOME,
  [URL_NAME_MAIN]: URL_HOME,
  [URL_NAME_LOGIN]: URL_LOGIN,
  [URL_NAME_LEGAL]: URL_LEGAL,
  [URL_NAME_SELECT_LOGIN]: URL_SELECTLOGIN,
  [URL_NAME_SIGN_UP]: URL_REGISTER,
  [URL_NAME_SEARCH]: URL_SEARCH,
  [URL_NAME_CATALOGUE]: URL_CATALOGUE,
  [URL_NAME_LIVE]: URL_LIVE,
  [URL_NAME_CONTENT]: URL_CONTENT,
  [URL_NAME_PROFILE]: URL_USER,
  [URL_NAME_RANKING]: URL_RANKING,
  [URL_NAME_PUBLISH]: URL_PUBLISH,
  [URL_NAME_REJECT]: URL_REJECT,
  [URL_NAME_PRODUCTS]: URL_PRODUCTS,
  [URL_SUCCESS_CHECKOUT]: URL_SUCCESS_CHECKOUT,
  [URL_CANCEL_CHECKOUT]: URL_CANCEL_CHECKOUT,
};

/**
 * Bloques de secciones
 */
export const SECTIONS_UPPER_BLOCK = [URL_HOME, URL_CATALOGUE, URL_RANKING];

export const URL_CERTIFICATE = "/certificate";
