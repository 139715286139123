import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {useTranslation} from 'react-i18next';

import './ButtonEllipsis.css';
import MenuApiComponent from "../../../../app/src/Pages/Menu/MenuApiComponent";

const ITEM_HEIGHT = 24;

export default function ButtonEllipsis(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const {t} = useTranslation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const options = [
        <div className="EllipEdit" key="edit">
            <ion-icon class="Menu" name="pencil"
    style={{color: "black", fontSize: "90%", marginRight: "5px"}}/>
            {t('ficha.edit')}
        </div>,
        <div className="EllipEdit" key="del">
            <ion-icon class="Menu" name="trash"
    style={{color: "black", fontSize: "90%", marginRight: "5px"}}/>
            {t('ficha.borrar')}
        </div>,
    ];

    const handleSubmit = (index, key) => {
        var arrayTemp = [];

        if (key === "del") {
            if (index !== null && index !== undefined) {
                arrayTemp = props.events.comments?.find(comment => comment.id === index)

                if (props.events.userName === arrayTemp.user) {
                    var add = new props.events.events.RemoveComment(index);
                    props.events.controller?.dispatch(add);
                }
            }
        } else if (key === "edit") {
            if (index !== null && index !== undefined) {
                arrayTemp = props.events.comments?.find(comment => comment.id === index)

                if (props.events.userName === arrayTemp.user) {
                    props.editCommmets(index)
                }
            }
        }
    }

    return (
        <div className="ButtonEllipsis" style={{marginLeft: "90px"}}>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}>
                <ion-icon class="Menu" name="ellipsis-vertical" style={{fontSize: "70%", marginTop: "-5px"}}/>
            </IconButton>
            <MenuApiComponent
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch'}
                }}>
                {options.map((option) => (
                    <MenuItem key={props.id + "-" + option.key} onClick={() => handleSubmit(props.id, option.key)}>
                        {option}
                    </MenuItem>
                ))}
            </MenuApiComponent>
        </div>
    );
}