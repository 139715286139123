import React, {useContext, useEffect, useRef, useState} from 'react';
import {Link, Redirect, useParams} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import moment from "moment";
import {useSelector} from "react-redux";
import 'moment/locale/gl';
import {URL_HOME, URL_PLAYER} from "@components/Routes/Location";
import {toArray} from '@components/Kotlin/KotlinUtils';
import AlertNotification from '@components/GUI/Alert/Alert';
import {User} from "@user/Context/User";
import Comments from "@detail/Pages/DetailPage/Comments";
import Casting from "@detail/Pages/DetailPage/Casting";
import Playlist from "@detail/Pages/DetailPage/Playlist";
import Favorite from "@detail/Pages/DetailPage/Favorite";
import Rating from "@detail/Pages/DetailPage/Rating";
import VideoTag from "@detail/Pages/DetailPage/VideoTag";
import VideoType from "@detail/Pages/DetailPage/VideoType";
import './VideoDetailPage.css';
import {detail as detailCore, user, interops} from '@core';
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import './ReportContent.css';
import backgroundDegraded from '@app/Resources/Images/degradado600.png';
import {Row, Col} from 'reactstrap';
import {
    DETAIL_PLAYABLE_CHILDREN,
    DETAIL_PLAYABLE_DIRECT,
    DETAIL_PLAYABLE_DISABLED, DETAIL_TYPE_CONTAINER,
    METADATA_CONTENT_REPORTED,
    METADATA_GAME_ACTION_SENT,
} from "./VideoDetailPageConstants";
import {
    turnOffLoadingActionCreator,
    turnOnLoadingActionCreator,
} from '../../../../app/src/actions/commonActions';
import ReportContentModalComponent from './ReportContentModalComponent';
import ShareContentModalComponent from './ShareContentModalComponent';
import {
    compareUserProfileObjects,
    setAchievementsAndRewardsInLocalStorage, useQuery,
} from '../../../../app/src/Utils/utils';
import ExpiredSessionModalComponent from '../../../../app/src/Components/common/ExpiredSessionModalComponent';
import useOutput from '../../../../app/src/Utils/Hooks/useOutput';
import {
    URL_LOGIN,
    URL_PRODUCTS,
    URL_VIDEODETAIL
} from '../../../../app/src/Components/Routes/Location';
import {
    PLAYBACK_LOCK_REASON_GUEST,
    PLAYBACK_LOCK_REASON_RATING, PLAYBACK_SUPPORT_STATUS_BUY_DISABLED,
    PLAYBACK_SUPPORT_STATUS_LOCKED_RATING, PLAYBACK_SUPPORT_STATUS_MISSING_ACHIEVEMENTS,
    PLAYBACK_SUPPORT_STATUS_MISSING_PRODUCT,
    PLAYBACK_SUPPORT_STATUS_NO_PLATFORM_SUPPORT,
    PLAYBACK_SUPPORT_STATUS_READY,
    PLAYBACK_SUPPORT_STATUS_USER_SESSION_REQUIRED,
} from '../../../../player/src/Pages/Player/PlayerConstants';
import {
    DATA_LOADING_STATUS_LOADED,
    DATA_LOADING_STATUS_LOADING, VIDEO_TYPE_LIVE, VIDEO_TYPE_LIVE_TIIVII,
    WIDGET_ACTION_DETAIL
} from '../../../../app/src/Utils/Constants';
import QRModalComponent from '../../../../app/src/Components/common/QRModalComponent';
import {ACTION_GAME} from '../../../../home/src/Pages/Home/HomeComponentConstants';
import ErrorMessageModalComponent from '../../../../app/src/Components/common/ErrorMessageModalComponent';
import {useKeycloak} from "@react-keycloak/web";
import {
    TENANT,
    TENANT_PETISGO,
    TENANT_TIIVII,
    MULTITENANT_CONSTANTS
} from "../../../../app/src/Resources/Multitenant/tenantConstants";
import CalendarButtonComponent from "./CalendarButtonComponent";
import ShareIcon from '@material-ui/icons/Share';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

function VideoDetailPage(props) {
    const {userAppController, scrollRef} = props;

    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useTranslation();
    let query = useQuery();
    let {id} = useParams();

    const PAYLOAD_CONST = "payload";
    let payload = query.get(PAYLOAD_CONST);

    const titleColor = document.getElementById("root").style.getPropertyValue("--rowTitleColor");

    const [detail, setDetail] = useState(null);

    const [controller, setController] = useState(null);
    const [displayOptions, setDisplayOptions] = useState(null);
    const [sentMessage, setSentMessage] = useState(false);
    const [reason, setReason] = useState(null);
    const [reasonDescription, setReasonDescription] = useState(undefined);
    const [reportSuccess, setReportSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [shareContent, setShareContent] = useState(null);
    const [sessionExpiredModal, setSessionExpiredModal] = useState(false);
    const [displayQRModal, setDisplayQRModal] = useState(false);
    const [messageModal, setMessageModal] = useState(false);
    const {userName, reloadUser} = useContext(User);
    const [titleChildren, setTitleChildren] = useState("ficha.Content");
    const [userLanguage, setUserLanguage] = useState(undefined);
    const [calendarButton, setCalendarButton] = useState(null);

    const [userController, setUserController] = useState(null);
    const [checkoutURL, setCheckoutURL] = useState(null);

    const [textMessageErrorBody, setTextMessageErrorBody] = useState("error.modal.rating.message");

    const refOptions = useRef(null);
    const refShare = useRef(null);
    const outputFn = useOutput();

    const con = new detailCore.controller();
    const tenantStyles = useSelector(state => state.commonState.tenantStyles);

    const loadTenantLocale = () => {
        moment.locale(tenantStyles.momentLocale)
    }

    useEffect(() => {
        loadTenantLocale();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleShareContent = () => {
        setShareContent(!shareContent);
    };

    const hideShareModal = () => {
        setShareContent(false);
    };

    const toggleOptions = () => {
        setDisplayOptions(!displayOptions);
    };

    const handleReportContent = () => {
        reportContentAux(reason.id, reasonDescription, detailCore.events.ReportContent)
        setReason(null);
    };

    const reportContentAux = (value, argument, method) => {
        if (sentReport) {
            sentReport();
        }
        if (controller && method) {
            const ev = new method(value, argument);
            controller.dispatch(ev);
        }
        setSentMessage(true);
    };

    const handleCloseModal = () => {
        setReasonDescription(undefined);
        setSentMessage(false);
        setDisplayOptions(false);
    };

    const handleSetReason = (value) => {
        setReason(value);
    };

    const handleSetReasonDescription = (event) => {
        setReasonDescription(event.target.value);
    };

    const hideOptions = () => {
        setReason(null);
        setDisplayOptions(false);
    };

    const turnOnLoading = () => {
        dispatch(turnOnLoadingActionCreator());
    };

    const turnOffLoading = () => {
        dispatch(turnOffLoadingActionCreator());
    };

    useEffect(() => {
        turnOnLoading();
        let binding = con.bind(model, o => outputFn(o, output, detailCore));
        const ev = new detailCore.events.LoadContent(id, payload);
        con.dispatch(ev);
        con.dispatch(detailCore.events.LogDetailView);

        setController(con);

        return () => con.unbind(binding);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (controller) {
            const userCon = userAppController;
            let userBinding = userCon.bind(userModel, (o) =>
                outputFn(o, userOutput, user)
            );
            userCon.dispatch(user.events.LogUserProfileView);
            setUserController(userCon);

            return () => {
                userCon.unbind(userBinding);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller]);

    const handleRedirectToMySubscriptions = () => {
        const successUrl = `${window.location.origin}/user`;
        userController?.dispatch(new user.events.GetProductsDashboard(successUrl));
    }

    useEffect(() => {
        handleRedirectToMySubscriptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userController]);

    const seasonTitle = (typeTitle) => {
        return TENANT === TENANT_TIIVII ? typeTitle + "_TIIVII" : typeTitle;
    }

    const model = (o) => {
        const formattedModel = {};

        formattedModel.relatedContent = o.relatedContent;
        formattedModel.content = o.content;
        formattedModel.playable = o.playable;
        formattedModel.canRate = o.canRate;
        formattedModel.canFav = o.canFav;
        formattedModel.isFavorite = o.isFavorite;
        formattedModel.loading = o.isContentLoading.name$;
        formattedModel.canReport = o.canReport;
        formattedModel.isRelatedContentLoading = o.isRelatedContentLoading.name$;
        formattedModel.isSerialContentLoading = o.isSerialContentLoading.name$;

        if (o.shareUrl) {
            formattedModel.shareUrl = o.shareUrl;
        }

        if (o?.nextContent) {
            formattedModel.nextContent = o.nextContent;
        }

        if (o?.comments?.comments) {
            formattedModel.comments = toArray(o?.comments?.comments);
        }

        if (o.availableReportReasons) {
            formattedModel.availableReportReasons = toArray(o.availableReportReasons);
        }

        if (o.serialContent) {
            let sc = toArray(o.serialContent)
            formattedModel.serialContent = sc.sort((a, b) => (a.episode > b.episode) ? 1 : ((b.episode > a.episode) ? -1 : 0));
            if (sc[0]?.type.name$ === sc[sc.length - 1]?.type.name$) {
                sc[0]?.type.name$ === DETAIL_TYPE_CONTAINER ?
                    setTitleChildren('ficha.' + seasonTitle(sc[0]?.type.name$)) :
                    !!sc[0]?.type.name$ && setTitleChildren('ficha.' + seasonTitle('VOD'));
            } else setTitleChildren('ficha.Content');
        }

        if (o.serialContentsTitle) {
            formattedModel.serialContentsTitle = o.serialContentsTitle;
        }
        if (o.playbackSupport) {
            formattedModel.playbackSupportName = o.playbackSupport.name();
            formattedModel.isReady = o.playbackSupport.name() === PLAYBACK_SUPPORT_STATUS_READY;
            formattedModel.isFree = formattedModel.isReady ? o.playbackSupport.isFree : false;

        }
        if (o.playbackSupport?.products) {
            formattedModel.products = toArray(o.playbackSupport.products);
        }

        if (formattedModel.loading === DATA_LOADING_STATUS_LOADED && compareUserProfileObjects(detail, formattedModel)) {
            if (formattedModel.content?.id == id) {
                turnOffLoading();
            }

            scrollRef.current?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});

            setDetail(formattedModel);
        }
    }

    const output = (o) => {
        if (o.error) {
            setError(true);
        }

        if (o.constructor?.$metadata$?.simpleName === METADATA_CONTENT_REPORTED) {
            setReportSuccess(true)
        }

        if (o.constructor.$metadata$.simpleName === METADATA_GAME_ACTION_SENT) {
            if (reloadUser) {
                reloadUser();
            }
        }
        if (o.constructor.$metadata$.simpleName === ACTION_GAME) {
            setAchievementsAndRewardsInLocalStorage(o);
        }

        if (interops.getKotlinName(o) === PLAYBACK_LOCK_REASON_GUEST ||
            interops.getKotlinName(o) === PLAYBACK_LOCK_REASON_RATING || !!o.code) {
            turnOffLoading();
            !!o.code && setTextMessageErrorBody("error.modal.common.title");
            setMessageModal(true);
        }
    }

    const userModel = o => {
        if (o.language && controller) {
            const ev = new detailCore.events.LoadContent(id);
            controller.dispatch(ev);
            controller.dispatch(detailCore.events.LogDetailView);
        }
        if (o.loadingProductsDashboard.name$ === DATA_LOADING_STATUS_LOADED && o.latestProductsDashboard.url) {
            setCheckoutURL(o.latestProductsDashboard.url)
        }
        setUserLanguage(o.language);
    };

    const userOutput = () => {
    };

    const sentReport = () => {
        setReportSuccess(false);
    };

    if (!id) {
        return <Redirect to={URL_HOME}/>;
    }

    const selectedVideo = (id) => {
        controller?.dispatch(new detailCore.events.LogSelectItem(id));
    };

    const handleCloseSessionModal = () => {
        setSessionExpiredModal(false);
    };

    const handleGoToPlayer = array => {

        const filteredArray = array.filter(item => item.type.name$ !== DETAIL_TYPE_CONTAINER)
        history.push(URL_PLAYER + "/" + filteredArray[0].id)
    };

    const handleQRModal = () => {
        setDisplayQRModal(displayQRModal => !displayQRModal)
    };

    const handleGoToLogin = () => {
        /*history.push(URL_LOGIN)*/
    };
    const handleGoToSeeContent = () => {
        history.push(URL_PLAYER + "/" + detail?.content?.id)
    };

    const handleGoToSeeNextContent = () => {
        turnOnLoading();
        history.push(URL_VIDEODETAIL + "/" + detail?.nextContent?.id)
    };

    const {keycloak, initialized} = useKeycloak();

    const isLogin = () => {
        return initialized && keycloak.authenticated;
    }

    const loadComponent = (statusLoader, content) => {
        return ((statusLoader === DATA_LOADING_STATUS_LOADING)
            || (statusLoader === DATA_LOADING_STATUS_LOADED &&
                content.length > 0))
    }

    const groupButtonsContent = () => {
        const detailPlayingName = detail?.playable?.name$;
        const detailPlaybackSupportName = detail?.playbackSupportName;

        return (detailPlaybackSupportName === PLAYBACK_SUPPORT_STATUS_READY && !isLogin()) ||
        (detailPlayingName === DETAIL_PLAYABLE_DIRECT || !isLogin()) ||
        (detailPlayingName === PLAYBACK_SUPPORT_STATUS_MISSING_ACHIEVEMENTS) ?
            <div className="boton-play">
                <button className="BotonVer"
                        onClick={handleGoToSeeContent}>
                    <PlayCircleOutlineIcon/>
                    {t('ficha.vercontido')}
                </button>
            </div> :
            (detailPlayingName === DETAIL_PLAYABLE_DISABLED && !isLogin()) ||
            (detailPlaybackSupportName === PLAYBACK_SUPPORT_STATUS_USER_SESSION_REQUIRED) ||
            (detailPlaybackSupportName === PLAYBACK_SUPPORT_STATUS_LOCKED_RATING && !isLogin()) ?
                <div className="boton-play">
                    <button className="BotonVer"
                            onClick={handleGoToLogin}>
                        <PlayCircleOutlineIcon/>
                        {t('ficha.vercontido')}
                    </button>
                </div> :
                (detailPlayingName === DETAIL_PLAYABLE_DISABLED &&
                    detailPlaybackSupportName === PLAYBACK_SUPPORT_STATUS_MISSING_PRODUCT) ?
                    <div className="boton-play">
                        <button className="BotonVer"
                                onClick={() => window.open(URL_PRODUCTS + '/' + id)}>
                            {t('products.subscribe')}
                        </button>
                    </div> :
                    detailPlayingName === DETAIL_PLAYABLE_CHILDREN && detail.serialContent?.length > 0 ?
                        <div className="boton-play">
                            <button className="BotonVer"
                                    onClick={() => handleGoToPlayer(detail.serialContent)}>
                                <PlayCircleOutlineIcon/>
                                {t('ficha.comezarver')}
                                
                            </button>
                        </div> :
                        detailPlayingName === DETAIL_PLAYABLE_DISABLED && detail.shareUrl &&
                        detailPlaybackSupportName === PLAYBACK_SUPPORT_STATUS_NO_PLATFORM_SUPPORT ?
                            <div className="boton-play">
                                <button className="BotonVer"
                                        onClick={handleQRModal}>
                                    <PlayCircleOutlineIcon/>
                                    {t('ficha.comezarver')}
                                </button>
                            </div> :
                            detailPlaybackSupportName === PLAYBACK_SUPPORT_STATUS_BUY_DISABLED ?
                                <div className="boton-play">
                                    <button className={`BotonVer ${!checkoutURL && "buttonDisabled "}`}
                                            disabled={!checkoutURL}
                                            onClick={!!checkoutURL && (() => window.open(checkoutURL))}>
                                        {t('products.subscribe')}
                                    </button>
                                </div>
                                :
                                <></>
    };


    const extraContent = (detail) => {

        return <>
            {(detail?.isFree || !detail?.isFree) && <div className={"textBlock"}>
                <div className={"textTitle alignLeft"}>{t('ficha.tipocontenido')}</div>
                {detail?.isFree ? <div className={"textInfo"}>
                        {t('ficha.opciondecontenidogratis')}</div> :
                    <div className={"textInfo"}>{t('ficha.opciondecontenidopago')}</div>}
            </div>}
            {detail?.products?.length > 0 && <div className={"textBlock"}>
                <div className={"textTitle alignLeft"}>{t('ficha.productos')}</div>
                {detail?.products.map(producto => (<div className={"textInfo"}>{producto?.name}</div>))}
            </div>}
            {(detail?.products?.length === 0 && !detail?.isFree) && <div className={"textBlock"}>
                <div className={"textTitle alignLeft"}>{t('ficha.productos')}</div>
                <div className={"textInfo"}>{t('ficha.opciondecontenidocomprado')}</div>
            </div>}
        </>
    }

    const getTitle = () => {
        return !!detail?.serialContentsTitle ?
            detail.serialContentsTitle.forLanguage(userLanguage)
            : t(titleChildren);
    }

    const renderCalendarButton = () => {
        const contentTypeCondition = detail?.content?.type?.name$ === VIDEO_TYPE_LIVE || detail?.content?.type?.name$ === VIDEO_TYPE_LIVE_TIIVII;
        const contentDate = new Date(detail.content.startTimeMs);
        const now = new Date();

        const dateCondition = contentDate > now;
        setCalendarButton(!!contentTypeCondition && !!dateCondition);
    };

    useEffect(() => {
        if (!!detail) {
            renderCalendarButton();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    return (
        <div className="VideoDetailPage" id="detailContainer">
            {error && <AlertNotification/>}
            {reportSuccess &&
            <AlertNotification type="success" showtitle={false} text={t('reportcontent.success')}/>}
            {detail !== null && detail.content && <>
                <div className={"principalImage"}>
                    <img src={detail?.content?.background?.url} alt={"Cover image"}/>
                    <div className={"contentPrincipalImage"}>
                        <div className="FichaOverContainer"
                             style={{backgroundImage: "url(" + backgroundDegraded + ")"}}>
                            <div className="FichaTitle">{detail?.content?.title}
                                {MULTITENANT_CONSTANTS[TENANT].showAuthor &&
                                <span
                                    className={"ContentAuthor"}> {t('reportcontent.author')} {detail?.content?.author}</span>}
                            </div>
                            <div className="VotesCategoriesContainer">
                                {MULTITENANT_CONSTANTS[TENANT].showScore &&
                                <Rating controller={controller} editRating={detail?.canRate} events={detailCore.events}
                                        totalRating={detail?.content?.score} votes={detail?.content?.votes} id={id}/>
                                }

                                <VideoType subtitle={detail?.content}/>
                                {MULTITENANT_CONSTANTS[TENANT].showVideoTags && detail?.content?.tags &&
                                toArray(detail?.content?.tags).map((t, index) =>
                                    <VideoTag tag={t} key={t + '-' + index}/>)}
                            </div>
                            <div className="PlayFavContainer">
                                {groupButtonsContent()}
                                {(detail?.playable?.name$ === DETAIL_PLAYABLE_DIRECT) &&
                                (detail?.content?.parentId !== null && detail?.nextContent?.parentId === detail?.content?.parentId) &&
                                    <button className="BotonVer"
                                            onClick={handleGoToSeeNextContent}>
                                        {t('ficha.watchNext')}
                                    </button>
                                }
                                {detail?.canFav &&
                                <Favorite controller={controller} events={detailCore.events}
                                          isFavorite={detail?.isFavorite}/>}
                                <div className="Share" onClick={() => handleShareContent()}>
                                        {/* <img src={shareIcon} alt={"share"} className="share-icon"/> */}
                                        <ShareIcon/>
                                </div>
                                {calendarButton && <CalendarButtonComponent detail={detail}/>}
                            </div>
                            <div className="InfoPrevia">
                                {detail?.content?.category && MULTITENANT_CONSTANTS[TENANT].showCategory &&
                                <span className="Xenero">
                                {detail?.content?.category}
                            </span>}
                                {detail?.content?.publishDate && MULTITENANT_CONSTANTS[TENANT].showPublishDate &&
                                <span className="Data">
                            {moment(detail?.content.publishDate, 'YYYY-MM-DD').format("ll")}
                    </span>}
                                {detail?.content?.seasons &&
                                <span className="Temporadas">
                        {t('ficha.seasons', {seasons: detail?.content.seasons})}
                    </span>}
                                {detail?.content?.rating && MULTITENANT_CONSTANTS[TENANT].showRating &&
                                <span className="Idade">
                        {t('ficha.rating', {rating: detail?.content.rating})}
                    </span>}
                                {!!detail?.content?.parentId && MULTITENANT_CONSTANTS[TENANT].showBackToSeason &&
                                <span className="button-back">
                                <Link to={URL_VIDEODETAIL + "/" + detail?.content?.parentId}
                                      className="">
                                    {t('ficha.backToSeason')}
                                </Link>
                            </span>
                                }
                            </div>
                            {detail.canReport && MULTITENANT_CONSTANTS[TENANT].showReportContentOption &&
                            <div className="ReportContentContainer">
                                <div className="ReportContent">
                                    <div className="ReportContentButton" onClick={() => toggleOptions()}>
                            <span className="ReportIcon">
                            {t('reportcontent.reportbuttonicon')}
                            </span>
                                        <span className="ReportText">
                            {t('reportcontent.reportbutton')}
                            </span>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
                {/* <div className={getClassVistaPrevia()}
                    style={{backgroundImage: "url(" + detail?.content?.image?.url + ")"}}
                >
                    <div className="FichaOverContainer"
                        style={{backgroundImage: "url(" + backgroundDegraded + ")"}}>
                        <div className="FichaTitle">{detail?.content?.title}
                            {MULTITENANT_CONSTANTS[TENANT].showAuthor &&
                        <span
                            className={"ContentAuthor"}> {t('reportcontent.author')} {detail?.content?.author}</span>}
                        </div>
                        <div className="VotesCategoriesContainer">
                            {MULTITENANT_CONSTANTS[TENANT].showScore &&
                                <Rating controller={controller} editRating={detail?.canRate} events={detailCore.events}
                                    totalRating={detail?.content?.score} votes={detail?.content?.votes} id={id}/>
                            }

                            <VideoType subtitle={detail?.content}/>
                            {MULTITENANT_CONSTANTS[TENANT].showVideoTags && detail?.content?.tags &&
                            toArray(detail?.content?.tags).map((t, index) =>
                                <VideoTag tag={t} key={t + '-' + index}/>)}
                        </div>
                        <div className="PlayFavContainer">
                            {groupButtonsContent()}
                            {(detail?.playable?.name$ === DETAIL_PLAYABLE_DIRECT) &&
                            (detail?.content?.parentId!== null && detail?.nextContent?.parentId === detail?.content?.parentId) &&
                            <div className="boton-play" onClick={()=> turnOnLoading()}>
                                <button className="SecondaryButton button-focused"
                                    onClick={handleGoToSeeNextContent}>
                                    {t('ficha.watchNext')}
                                </button>
                            </div>}
                            {detail?.canFav &&
                            <Favorite controller={controller} events={detailCore.events}
                                    isFavorite={detail?.isFavorite}/>}
                            <div className="Share">
                                <div type="button" className="BotonQuitarFav button-default share-container"
                                    onClick={() => handleShareContent()}>
                                    <img src={shareIcon} alt={"share"} className="share-icon"/>
                                </div>
                            </div>
                        </div>
                        <div className="InfoPrevia">
                            {detail?.content?.category && MULTITENANT_CONSTANTS[TENANT].showCategory &&
                            <span className="Xenero">
                                {detail?.content?.category}
                            </span>}
                            {detail?.content?.publishDate && MULTITENANT_CONSTANTS[TENANT].showPublishDate &&
                            <span className="Data">
                            {moment(detail?.content.publishDate, 'YYYY-MM-DD').format("ll")}
                    </span>}
                            {detail?.content?.seasons &&
                            <span className="Temporadas">
                        {t('ficha.seasons', {seasons: detail?.content.seasons})}
                    </span>}
                            {detail?.content?.rating && MULTITENANT_CONSTANTS[TENANT].showRating &&
                            <span className="Idade">
                        {t('ficha.rating', {rating: detail?.content.rating})}
                    </span>}
                            {!!detail?.content?.parentId && MULTITENANT_CONSTANTS[TENANT].showBackToSeason &&
                            <span className="button-back">
                                <Link to={URL_VIDEODETAIL + "/" + detail?.content?.parentId}
                                    className="">
                                    {t('ficha.backToSeason')}
                                </Link>
                            </span>
                            }
                        </div>
                        {detail.canReport && MULTITENANT_CONSTANTS[TENANT].showReportContentOption && <div className="ReportContentContainer">
                            <div className="ReportContent">
                            <div className="ReportContentButton" onClick={() => toggleOptions()}>
                            <span className="ReportIcon">
                            {t('reportcontent.reportbuttonicon')}
                            </span>
                            <span className="ReportText">
                            {t('reportcontent.reportbutton')}
                            </span>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>*/}
                {loadComponent(detail?.isSerialContentLoading, detail.serialContent) &&
                <div className={"playlistContainer serialContent"}>
                    <Playlist clickCallback={selectedVideo}
                              playlist={detail.serialContent} typeBanner="Banner" detail={true}
                              className="FrameworkHome" turnOffLoading={turnOffLoading} turnOnLoading={turnOnLoading}
                              access={WIDGET_ACTION_DETAIL} titleColor={titleColor} contentId={detail.content.id}
                              statusLoader={detail?.isSerialContentLoading}
                              titulo={getTitle()}
                    />
                </div>}
                <div className={"contentContainer"}>
                    <Row>
                        <Col md={12} lg={6}>
                            <p className="DescriptionTitle">{t('detail.description')}</p>
                            <div className="DescriptionDetail">
                                {detail?.content?.description}
                            </div>
                        </Col>
                        {MULTITENANT_CONSTANTS[TENANT].showVideoDetails &&
                        <Col md={12} lg={5}>
                            <div className={"textDetailContainer"}>
                                {MULTITENANT_CONSTANTS[TENANT].showAuthor &&
                                    detail?.content?.author !== null &&
                                <div className={"textBlock"}>
                                    <div className={"textTitle alignLeft"}>{t('ficha.autor')}</div>
                                    <div className={"textInfo"}>{detail?.content?.author}</div>
                                </div>}
                                {detail?.content?.category !== null && <div className={"textBlock"}>
                                    <div className={"textTitle alignLeft"}>{t('ficha.categoria')}</div>
                                    <div className={"textInfo"}>{detail?.content?.category}</div>
                                </div>}
                                {detail?.content?.subcategory !== null && <div className={"textBlock"}>
                                    <div className={"textTitle alignLeft"}>{t('ficha.subcategoria')}</div>
                                    <div className={"textInfo"}>{detail?.content?.subcategory}</div>
                                </div>}
                                {detail?.content?.publishDate !== null && <div className={"textBlock"}>
                                    <div className={"textTitle alignLeft"}>{t('ficha.estreno')}</div>
                                    <div className={"textInfo"}>{moment(detail?.content?.publishDate).format('DD-MM-YYYY')}</div>
                                </div>}
                                {TENANT !== TENANT_PETISGO && extraContent(detail)}
                            </div>
                        </Col>
                        }
                    </Row>
                    {toArray(detail?.content?.participants).length > 0 &&
                    <Casting className="FichaCasting" participants={toArray(detail?.content?.participants)}/>}
                    <div className="CommentsContainer">
                        {MULTITENANT_CONSTANTS[TENANT].showComments && detail.comments &&
                        <Comments comments={detail.comments} userName={userName}
                                  areMoreComments={detail.areMoreCommentsAvailable}
                                  titulo={t('ficha.doacomment')} placeholder={t('ficha.commentplaceholder')}
                                  controller={controller} events={detailCore.events}
                                  avatar={detailCore.userAvatar?.url}/>}
                    </div>

                </div>
                <div className="FichaInfoRelated">
                    {loadComponent(detail?.isRelatedContentLoading, detail?.relatedContent.toArray()) &&
                    <Playlist titulo={t('ficha.explora')} clickCallback={selectedVideo} hideSubtitle={true}
                              playlist={detail?.relatedContent.toArray()} typeBanner="Banner" detail={true}
                              className="FrameworkHome" turnOffLoading={turnOffLoading} turnOnLoading={turnOnLoading}
                              access={WIDGET_ACTION_DETAIL} titleColor={titleColor} contentId={detail.content.id}
                    />}
                </div>
                {displayOptions &&
                <ReportContentModalComponent refOptions={refOptions}
                                             availableReportReasons={detail.availableReportReasons}
                                             hideOptions={hideOptions}
                                             reason={reason}
                                             handleSetReason={handleSetReason}
                                             reasonDescription={reasonDescription}
                                             handleSetReasonDescription={handleSetReasonDescription}
                                             handleReportContent={handleReportContent}
                                             handleCloseModal={handleCloseModal} sentMessage={sentMessage}/>}
                {shareContent && <ShareContentModalComponent hideShareModal={hideShareModal} refShare={refShare}
                                                             title={detail?.content?.title}
                                                             description={detail?.content?.description}/>}
            </>}
            {messageModal && <ErrorMessageModalComponent textTitle="error.modal.common.title" textBody={textMessageErrorBody}/>}
            {sessionExpiredModal && <ExpiredSessionModalComponent handleCloseModal={handleCloseSessionModal}/>}
            {displayQRModal &&
            <QRModalComponent url={detail?.shareUrl} goBack={false} handleCloseQRModal={handleQRModal}/>}
        </div>);
}

export default VideoDetailPage;